import React from 'react'
import HeaderView from './component/header'
import { Spin } from 'antd'
import './index.less'
const IconFont = Loader.loadBaseComponent('IconFont')
const NoData = Loader.loadBaseComponent('NoData')
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap')
const Wrapper = Loader.loadBusinessComponent('BaseLibDetails', 'Wrapper')
const AccessControlCard = Loader.loadBusinessComponent('Card', 'AccessControl');
const PageDetails = Loader.loadBusinessComponent('PageDetails')
const limit = 6
export default 
class BodyLibraryDetail extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      searchData: {},  
      loading: true,
      //默认选中id
      activeId: '',           
      //上一页数据
      preList: [],        
      //当前页数据              
      nowList: [],        
      //下一页数据     
      nextList: []                      
    }
  }
  componentWillMount(){
    const { location } = this.props;
    const { id } = Utils.queryFormat(location.search);
    window.LM_DB.get(
      'parameter', 
      id
    ).then(result => {
      this.setState({
        searchData: result.searchData,
        activeId: result.id,
        nowList: [result.data],
        loading: false
      })
      // this.getlog(data.data)
      /**获取当前页 */
      this.queryAccessControlList({
        endId: result.id,
        endTime: result.data.captureTime,
        limit: 5
      }).then(res => {
        if(res.length > 0){
          Promise.all([
            //下一页
            this.queryAccessControlList({
              endId: res[res.length - 1].id,
              endTime: res[res.length - 1].captureTime,
              limit
            }),
            //上一页
            this.queryAccessControlList({
              startId: result.id,
              startTime: result.data.captureTime,
              limit
            })
          ]).then(list => {
            this.setState({
              nowList: [result.data].concat(res),
              preList: list[1],
              nextList: list[0],
            })
          })
        }else{
          this.setState({
            nowList: [result.data].concat(res),
            preList: [],
            nextList: [],
          })
        }
      })
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  // /**
  //  * @desc 查看图库详情日志
  //  * @param {string} 当前选中图片id
  //  * @param {object} 当前展示数据
  //  */
  // getlog(data) {
  //   let infoList = Service.url.getLogInfoList()
  //   const params = infoList.find(v => v.moduleName === 'bodyDetailModule')
	// 	let logInfo = {
	// 	  ...params,
	// 		description: `查看点位【${data.deviceName}】 ${moment(+data.captureTime).format('YYYY.MM.DD HH:mm:ss')}的人体抓拍照片`
	// 	};
	// 	Service.logger.save(logInfo);
	// }

  changePageId = id => {
    const { preList, nowList, nextList, searchData } = this.state
    const allData = preList.concat(nowList).concat(nextList)
    const data = allData.find(v => v.id === id) || {}
    // this.getlog(data)
    LM_DB.add('parameter', {
      id, 
      data,
      searchData
    }).then(() => {
      BaseStore.tab.goPage({
        moduleName: 'accessControlDetail',
        active: 'replace',
        isUpdate: true,
        data: {id}
      })
    })
  }

  /**
   * @desc 获取上一页数据
   */
  getPreData = (activeId) => {
    const { preList, nowList } = this.state
    this.queryAccessControlList({
      startId: preList[0].id,
      startTime: preList[0].captureTime,
      limit,
    }).then(list => {
      this.setState({
        nextList: nowList,
        nowList: preList,
        preList: list,
        activeId: activeId || preList[0].id
      })
      this.changePageId(activeId || preList[0].id)
    })
  }

  /**
   * @desc 翻到下一页数据
   * @param {String} type pre: 上一页 next: 下一页
   */
  getNextData = () => {
    const { nextList, nowList } = this.state
    this.queryAccessControlList({
      endId: nextList[nextList.length - 1].id,
      endTime: nextList[nextList.length - 1].captureTime,
      limit
    }).then(list => {
      this.setState({
        nextList: list,
        nowList: nextList,
        preList: nowList,
        activeId: nextList[0].id
      })
      this.changePageId(nextList[0].id)
    })
  }

  /**
   * @desc 点击切换
   */
  changeActiveId = (activeId, type) => {
    this.changePageId(activeId)
    if(!this.state.nowList.find(v => v.id === activeId)){
      if(type === 'pre'){
        this.getPreData(activeId)
      }
      if(type === 'next'){
        this.getNextData()
      }
    }else{
      this.setState({activeId})
    }
  }

  /**
   * @desc 列表查询
   * @param {Object} options
   */
  queryAccessControlList = (options = {}) => {
    let searchData = Object.assign({}, this.state.searchData)
    // 处理数据
    return Service.community.queryAccessRecords(Utils.accessControlOptions({...searchData, ...options}))
      .then(res => {
        return res.data.list
      })
  }

  renderContent(){
    const {
      loading,
      activeId,
      nowList,
      preList,
      nextList
    } = this.state

    if(loading){
      return null
    }

    if(!!!nowList.length){
      return <NoData />
    }
    const data = nowList.find(v => v.id === activeId) || {}
    //data.videoUrl = 'http://doordustorage.oss-cn-shenzhen.aliyuncs.com/cctvVideo/DDD4011712-01484/2018/11/30/_468679393_1543548965184_1_2.mp4'
    //所有列表数据
    const allData = preList.concat(nowList).concat(nextList)
    let index = 0
    allData.find((v, i) => {
      if(v.id === activeId){
        index = i
      }
    })
    //上一条数据
    const preData = allData[index - 1]              
    //下一条第一条数据
    let nextData = allData[index + 1]
    return (
      <React.Fragment>
        <HeaderView data={data || {}}/>
        <div className='picture-container access-control-detail-imm'>
          {preData && <div className='nav-l'>
            <PageDetails 
              imgUrl={preData.pictureUrl}
              onChange={this.changeActiveId}
              id={preData.id}
              pageType='pre'
            />
          </div>}
          {nextData && <div className='nav-r'>
            <PageDetails 
              imgUrl={nextData.pictureUrl}
              id={nextData.id}
              onChange={this.changeActiveId}
            />
          </div>}
          <ImageMovieMap type="accessControl" video={true} data={data} key={data.id} searchData={this.state.searchData} hasStorageLimit={false}/>
        </div>
        <div className='footer-list-container'>
          {!!preList.length && <div className='cg l' onClick={() => this.getPreData()}><IconFont type='icon-S_Arrow_BigLeft' /></div>}
          {!!nextList.length && <div className='cg r' onClick={() => this.getNextData()}><IconFont type='icon-S_Arrow_BigRight' /></div>}
          <div className={`detail-list-item ${nowList.length !== 6 ? 'less' : ''}`}>
            {nowList.map(v => 
              <AccessControlCard 
                item={v}
                className='access-control-detail-card'
                hoverScale={false}
                active={activeId === v.id ? true : false}
                onClick={() => this.changeActiveId(v.id)}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render(){
    const { loading } = this.state
    return (
      <Wrapper>
        <Spin spinning={loading}>
          <div style={{ width: '100%', height: '100%', minHeight: 400 }}>{this.renderContent()}</div>
        </Spin>
      </Wrapper>
    )
  }
}