import React from 'react';
import './header.less';
const ImageView = Loader.loadBaseComponent('ImageView');
const LabelValue = Loader.loadBaseComponent('LabelValue')
export default class Header extends React.Component{
  render(){
    const { data } = this.props
    let accessTypeItem = Dict.map.openType.find(v => v.value === data.accessType)
    let accessType = '其他'
    if(accessTypeItem){
      accessType = accessTypeItem.label
    }
    return (
      <div className='access-control-detail'>
        <div className='pic-box'>
          <div className='img-box'>
            <ImageView src={data.portraitPictureUrl}/>
            <div className='pic-info'>登记照片</div>
          </div>
        </div>
        <div className='info-box'>
          <LabelValue className="item item-name" label="姓名" value={data.personName || '-'} />
          <LabelValue className="item" label="开门类型" value={accessType} />
          <LabelValue className="item" label="门禁卡卡号" value={data.accessCardNo || '-'} />
          <LabelValue className="item" label="门禁位置" value={data.address} />
          <LabelValue className="item" label="开门时间" value={Utils.formatTimeStamp(data.captureTime)} />
        </div>
      </div>
    )
  }
}
